@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main header
----------*/
$_root: '.l-mainheader-contact';

.l-mainheader-contact {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 100;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 16px);
		height: 100%;
		margin: 8px auto 0;
		background: #fff;
		border-radius: 5px;
		padding: 13px 20px 14px 10px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);

		a {
			position: relative;
		}
	}

	&__logo {
		position: relative;
		flex: 1 1 auto;
		max-width: 180px;
		margin: 0 20px 0 0;
		font-size: 0;
		z-index: 2;
	}

	@include m.mq-pc {
		&__inner {
			display: block;
			padding-bottom: 0;
			width: calc(100% - 30px);
			min-width: 870px;
			margin: 15px 15px 0;
			padding: 26px 30px 28px;
		}

		&__logo {
			max-width: 270px;
			margin-left: 0;
			transition: transform .3s;
		}
	}
}
