@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main footer
----------*/
$_root: '.l-mainfooter-contact';

.l-mainfooter-contact {
	position: relative;

	&__inner {
		padding-bottom: 30px;
		text-align: center;
	}

	&__copyright {
		font-family: var(--font-inter);
		font-size: 1.2rem;
		font-weight: bold;
		color: var(--color-txt-caption);
	}

	@include m.mq-pc {
		&__inner {
			padding-bottom: 40px;
		}
	}

	@media print {
		display: none;
	}
}
