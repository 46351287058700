@use 'sass:math';
@use '../_mixin' as m;
/*----------
	default margin
----------*/
// heading
.c-billboard,
.l-container-header {
	margin-bottom: 60px;
	@include m.mq-pc {
		margin-bottom: 80px;
	}
}

.l-default-margin {
	> [class^='c-'],
	> [class^='g-'] {
		margin-top: 40px;

		&:first-child {
			margin-top: 60px;
		}

		@include m.mq-pc {
			margin-top: 50px;

			&:first-child {
				margin-top: 80px;
			}
		}
	}

	> [class^='c-heading-'] {
		+ [class^=c-],
		+ [class^=g-] {
			margin-top: 0;
		}
	}

	> .c-heading-secondary {
		margin-top: 80px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
		margin-bottom: 40px;
		@include m.mq-pc {
			margin-top: 120px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
			margin-bottom: 50px;
		}
	}

	> .c-heading-tertiary {
		margin-top: 60px;
		margin-bottom: 50px;
		@include m.mq-pc {
			margin-top: 80px;
		}
	}

	> .c-heading-quaternary {
		margin-top: 40px;
		margin-bottom: 20px;
		@include m.mq-pc {
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}

	// button-container
	.g-button-container {
		margin-top: 60px;

		@include m.mq-pc {
			margin-top: 80px;
		}
	}

	// pagination
	.c-pagination {
		margin-top: 60px;

		@include m.mq-pc {
			margin-top: 80px;
		}
	}

	// separator
	.c-separator {
		margin-top: 60px;
		margin-bottom: 60px;

		@include m.mq-pc {
			margin-top: 80px;
			margin-bottom: 80px;
		}
	}

	// remarks
	.c-remarks {
		margin-top: 30px;
	}

	// Q&A
	.c-qa {
		+ .c-qa {
			margin-top: 0;
		}
	}

	// accordion
	.c-accordion {
		+ .c-accordion {
			margin-top: 0;
		}
	}
}

.l-main > .c-billboard:first-child {
	margin-top: -109px;
	@include m.mq-pc {
		margin-top: -186px;
	}
}
