@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main footer
----------*/
$_root: '.l-mainfooter';

/* stylelint-disable no-descending-specificity */
.l-mainfooter {
	position: relative;

	&__inner {
		padding-top: 210px;
		padding-bottom: 30px;
		background: var(--color-black);
	}

	&__utilities {
		@include m.inner-content;
		position: relative;
		margin-bottom: -150px;
	}

	&__content {
		@include m.inner-content;
		border-bottom: 1px solid #555555;
	}

	&__backhead {
		position: relative;
		z-index: 50;
		height: 0;
		padding: 0 20px;
		opacity: 0;
		transition: opacity .3s;
		pointer-events: none;
		margin-left: auto;
		width: 100%;

		&-inner {
			margin: auto;
			text-align: right;
			transform: translateY(-100%);
		}

		&.is-visible {
			opacity: 1;

			a {
				pointer-events: auto;
			}
		}

		&.is-stuck {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
		}

		a {
			content: '';
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: var(--color-prim);
			font-size: 0;
			color: #fff;
			box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
			transform: translateY(-20px);
		}

		i {
			font-size: 1.4rem;
		}
	}

	&__logo {
		margin-bottom: 40px;

		img {
			display: block;
			max-width: 200px;
		}
	}

	&__nav {
		color: #fff;
		margin-bottom: 35px;
	}

	&__copyright {
		margin-top: 30px;
		font-size: 1rem;
		font-weight: bold;
		color: var(--color-txt-caption);
	}

	@include m.mq-pc {
		&__inner {
			padding-top: 140px;
			padding-bottom: 40px;
		}

		&__utilities {
			display: flex;
			justify-content: center;
			margin-bottom: -40px;
		}

		&__content {
			display: flex;
			padding-bottom: 85px;
		}

		&__backhead {
			&-inner {
				margin-right: 10px;
			}

			a {
				transform: translateY(-30px);
				transition: background-color .2s ease-out;

				&:hover {
					background-color: #00CCFF;
				}
			}
		}

		&__logo {
			padding-right: 20px;

			img {
				max-width: 250px;
			}
		}

		&__nav {
			flex: 0 0 calc(100% - 270px);
			margin-left: auto;
			margin-right: auto;
		}

		&__copyright {
			margin-top: 0;
			font-size: 1.2rem;
		}
	}

	@media print {
		display: none;
	}
}

.l-mainfooter-utilities {
	&__item {
		> a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 17px 20px 18px;
			font-weight: 500;
			font-size: 1.4rem;
			color: #fff;
			background-color: var(--color-prim);
			transition: background-color .2s ease-out;
		}

		i {
			position: relative;
			top: .1rem;
			padding-right: 6px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #B4BABD;

			> a {
				background-color: var(--color-bluegray);
				color: var(--color-txt);
			}
		}

		&:first-child {
			> a {
				border-radius: 5px 5px 0 0;
			}
		}

		&:last-child {
			> a {
				border-radius: 0 0 5px 5px;
			}
		}
	}

	@include m.mq-pc {
		&__item {
			width: 400px;

			&:not(:last-child) {
				border-bottom: none;
				border-right: 1px solid #B4BABD;

				> a {
					&:hover {
						background-color: #E7F0F5;
					}
				}
			}

			> a {
				padding: 25px 40px;
				font-size: 1.6rem;

				&:hover {
					background-color: #00CCFF;
				}
			}

			i {
				top: .2rem;
				font-size: 2.2rem;
				padding-right: 22px;
			}

			&:first-child {
				> a {
					border-radius: 5px 0 0 5px;
				}
			}

			&:last-child {
				> a {
					border-radius: 0 5px 5px 0;
				}
			}
		}
	}
}

.l-fnav-main {
	display: flex;
	justify-content: space-between;

	&__secondary-item {
		font-weight: 500;
		font-size: 1.2rem;

		> a {
			color: inherit;

			> span {
				display: inline-block;
				padding: 7px 0;
			}

			> i {
				position: relative;
				top: .1rem;
				font-size: 1.4rem;
				margin-right: 6px;
			}
		}
	}

	@include m.mq-sp {

		&__cattop {
			position: relative;

			> a {
				display: block;
				color: inherit;

				> span {
					display: block;
					padding: 5px 30px 5px 20px;
					font-size: 1.5rem;
					font-weight: 500;
				}
			}

			> button {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				width: 60px;
				height: 100%;
				text-align: center;
				cursor: pointer;
			}

			&.is-hierarichical {
				> button {
					display: block;
					color: var(--color-txt);

					&::before {
						@include m.iconfont-default;
						content: var(--icon-plus);
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						font-size: 14px;
						line-height: 1;
						transform: translate(-50%, -50%);
					}

					&.is-open {
						&::before {
							content: var(--icon-minus);
						}
					}
				}
			}
		}
	}

	@include m.mq-pc {
		justify-content: flex-end;

		&__primary-items {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			width: 350px;
			height: 205px;
		}

		&__cattop {
			margin-bottom: 20px;
			font-size: 1.7rem;

			> button {
				display: none;
			}

			> a {
				color: inherit;
				transition: color .2s;

				&:hover {
					color: var(--color-txt-caption);
					text-decoration: underline;
				}

				> span {
					font-weight: 500;
				}

				> i {
					position: relative;
					top: .1rem;
					font-size: 1.4rem;
					margin-right: 6px;
				}
			}
		}

		&__primary-item {
			margin: 0 22px;
		}

		&__secondary-items {
			display: block;
			margin-left: 120px;
			padding-left: 50px;
			border-left: 1px solid #555555;
		}

		&__secondary-item {
			font-size: 1.3rem;

			> a {
				color: inherit;
				transition: color .2s;

				&:hover {
					color: var(--color-txt-caption);
				}
			}
		}

		&__secondary-item + &__secondary-item {
			margin-top: 8px;
		}
	}

	@media (min-width: 768px) and (max-width: 1200px) {
		&__secondary-items {
			margin-left: 10px;
			padding-left: 30px;
		}
	}
}

.l-fnav-sub {
	&__inner {
		@include m.inner-content;
		display: block;
		margin-top: 20px;
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	&__item {
		font-size: 1.2rem;
		line-height: 1;
		color: #fff;

		> a {
			color: inherit;

			> span {
				display: block;
				padding: 0 13px;
			}
		}

		&:first-of-type {
			> a {
				> span {
					padding-left: 0;
				}
			}
		}
	}

	&__item + &__item {
		border-left: 1px solid #fff;
	}

	@include m.mq-sp {
		&__item {
			margin-bottom: 10px;
		}
	}

	@include m.mq-pc {

		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 30px;
		}

		&__item {
			> a {
				transition: color .2s;

				> span {
					padding: 0 20px;
				}

				&:hover {
					color: var(--color-txt-caption);
				}
			}
		}
	}
}
/* stylelint-enable no-descending-specificity */
