@use 'sass:math';
@use '../_mixin' as m;
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700);
/*----------
	base
----------*/

html {
	width: 100%;
	font-size: 62.5%;
	background-color: #fff;

	&.is-locked {
		position: absolute;
		overflow: hidden;
	}
}

body {
	font-size: 1.6rem;
	line-height: var(--line-height-l);
	font-family: var(--font-sans);
	letter-spacing: 0;
	color: var(--color-txt);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	background-color: #F5F8F8;

	@include m.mq-sp {
		font-size: 1.4rem;
	}

	@include m.mq-pc {
		min-width: 900px;
		overflow-x: auto;
	}

	@media print {
		min-width: 1024px;
	}
}

a {
	text-decoration: none;
	color: var(--color-prim);
	@include m.mq-pc {
		&:hover {
			text-decoration: none;
		}
	}
}

img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

sup {
	font-size: 1.1rem;
	vertical-align: top;

	@include m.mq-pc {
		font-size: 1.3rem;
	}
}

sub {
	font-size: 1.1rem;
	vertical-align: bottom;

	@include m.mq-pc {
		font-size: 1.3rem;
	}
}
