@use 'sass:math';
@use '../_mixin' as m;
/*----------
	vars
----------*/

:root {
	/* color */
	--color-prim: #20A0E4;
	--color-seco: #33CDE7;
	--color-seco2: #1CC07D;
	--color-black: #333333;
	--color-bluegray: #D1DEE5;
	--color-heading-dark: #899FAB;
	--color-heading-thin: #E7F0F5;
	--color-txt: #222;
	--color-txt-caption: #888888;
	--color-txt-link: #20A0E4;
	--color-border: #ddd;
	--color-bg: #F5F8F8;
	--color-caution: #F00000;

	--color-prim-rgb: #{m.hex2rgb(#20A0E4)};
	--color-seco-rgb: #{m.hex2rgb(#33CDE7)};
	--color-seco2-rgb: #{m.hex2rgb(#1CC07D)};
	--color-black-rgb: #{m.hex2rgb(#333333)};
	--color-bluegray-rgb: #{m.hex2rgb(#D1DEE5)};
	--color-heading-dark-rgb: #{m.hex2rgb(#899FAB)};
	--color-heading-thin-rgb: #{m.hex2rgb(#E7F0F5)};
	--color-txt-rgb: #{m.hex2rgb(#222)};
	--color-txt-caption-rgb: #{m.hex2rgb(#888888)};
	--color-txt-link-rgb: #{m.hex2rgb(#00A0E8)};
	--color-border-rgb: #{m.hex2rgb(#ddd)};
	--color-bg-rgb: #{m.hex2rgb(#F5F8F8)};
	--color-caution-rgb: #{m.hex2rgb(#F00000)};


	/* font family */
	--font-sans: 'Noto Sans JP', 'ヒラギノ角ゴシック', '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', Hiragino Sans, 'メイリオ', Meiryo, sans-serif;
	--font-inter: 'Inter', sans-serif;

	/* line height */
	--line-height-s: 1.4;
	--line-height-m: 1.6;
	--line-height-l: 1.8;

	/* icons */
	@each $name, $glyph in m.$icons {
		--icon-#{$name}: '#{$glyph}';
	}
}

