/* Reset */
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: inherit;
	font-size: inherit;
	font-style: inherit;
	-webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
picture,
main,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ul,
ol {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
}

button {
	font-family: inherit;
}

b {
	font-weight: bold;
}
