@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main header
----------*/
$_root: '.l-mainheader';

.l-mainheader {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 16px);
		height: 100%;
		margin: 8px auto 0;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);

		a {
			position: relative;
		}
	}

	&__logo {
		position: relative;
		flex: 1 1 auto;
		max-width: 180px;
		margin: 0 20px 0 0;
		z-index: 2;

		> a {
			display: block;
		}
	}

	&__compactlogo {
		display: none;
	}

	&__navtoggle {
		position: relative;
		width: 40px;
		height: 17px;
		cursor: pointer;

		span {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 40px;
			height: 1px;
			background-color: var(--color-txt);
			border-radius: 4px;
			transition: all .5s;

			&:nth-child(2) {
				top: 8px;
			}

			&:nth-child(3) {
				top: 16px;
			}
		}
	}

	&__nav {
		z-index: 100;
		transition: all .2s;
		overflow: auto;
	}

	&.is-complete {
		&::after {
			opacity: .6;
		}
	}

	@include m.mq-sp {
		&__inner {
			height: 54px;
			padding: 0 20px 0 10px;
		}

		&__nav {
			width: 100%;
			position: absolute;
			top: 100%;
			left: 0;
			opacity: 0;
			transition: all .2s;
			overflow: auto;
			visibility: hidden;
			z-index: -10;
		}

		&__support {
			margin-top: 50px;
		}

		&__utilitie {
			text-align: center;
			font-size: 1.3rem;
			line-height: var(--line-height-s);
			margin: 0 15px;

			i {
				position: relative;
				top: .2rem;
				font-size: 1.4rem;
				margin-right: 7px;
			}

			&:nth-child(2) {
				margin-top: 40px;
			}
		}

		&__contact {
			margin-top: 20px;
			background: var(--color-prim);
			border: 1px solid var(--color-border);
			border-radius: 25px;
			font-size: 1.2rem;
			line-height: 1.6;
			text-align: center;
		}

		&__close {
			display: block;
			position: relative;
			width: 40px;
			height: 26px;
			// margin: 0 auto 30px;
			margin: 0 auto;
			cursor: pointer;

			span {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
				height: 1px;
				background-color: var(--color-txt);
				transform: translateY(12px) rotate(-327deg);

				&:nth-child(2) {
					top: 16px;
					transform: translateY(-4px) rotate(327deg);
				}
			}
		}

	}

	@include m.mq-pc {
		&__inner {
			display: block;
			height: 95px;
			padding-bottom: 0;
			width: calc(100% - 30px);
			min-width: 900px;
			margin: 15px 15px 0;
			padding: 0 30px;
		}

		&__logo {
			position: absolute;
			top: 50%;
			left: 30px;
			max-width: 270px;
			margin-left: 0;
			transform: translateY(-50%);
			transition: transform .3s;
		}

		&__mainlogo {
			opacity: 1;
		}

		&__compactlogo {
			width: 75px;
		}

		&__navtoggle {
			display: none;
		}

		&__nav-body {
			display: flex;
			flex-direction: column-reverse;
			position: static;
			width: 100%;
			opacity: 1;
		}

		&__supportlink {
			display: flex;
			justify-content: flex-end;
		}

		&__close {
			display: none;
		}
	}

	@media (min-width: 768px) and (max-width: 1190px) {
		&__logo {
			max-width: 100px;
			left: 20px;
		}

		&__inner {
			padding-left: 20px;
		}
	}

	@media print {
		display: none;
	}
}


/* sublink */
.l-mainheader-sublink {
	display: block;

	&__item {
		> a {
			font-weight: 500;
			font-size: 1.3rem;
			line-height: var(--line-height-s);
			color: var(--color-txt-caption);
		}

		i {
			position: relative;
			top: .2rem;
			font-size: 1.4rem;
			margin-right: 6px;
		}

		& + & {
			margin-left: 20px;
		}
	}

	@include m.mq-sp {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		// flex-direction: column-reverse;
	}

	@include m.mq-pc {
		display: flex;
		transform: translateY(3px);

		&__item {
			> a {
				transition: color .2s;

				&:hover {
					color: var(--color-prim);
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1150px) {
		&__item {
			margin-left: 10px;

			> a {
				font-size: 1.1rem;
			}
		}
	}
}


/* utilities */
.l-mainheader-utilities {
	&__item {
		text-align: center;

		> a {
			display: block;
			position: relative;
			padding: 11px 20px;
			margin: auto;
			color: #fff;
			font-size: 1.3rem;
			font-weight: 500;
			transition: background-color .3s, color .3s;
		}

		i {
			position: relative;
			top: .2rem;
			font-size: 1.4rem;
			margin-right: 7px;
		}
	}

	@include m.mq-sp {
		&__item {
			margin-bottom: 20px;
			text-align: left;

			> a {
				padding: 8px 20px 9px;
			}

			i {
				margin-right: 10px;
			}

			&:last-of-type {
				margin-bottom: 50px;
			}
		}

		&__downloadlink {
			max-width: percentage( math.div(200, 360) );
			background-color: var(--color-txt);
			border: 1px solid var(--color-txt);
			border-radius: 4px;
		}

		&__contactlink {
			max-width: percentage( math.div(200, 360) );
			background-color: var(--color-prim);
			border: 1px solid var(--color-prim);
			border-radius: 4px ;
		}
	}

	@include m.mq-pc {
		display: flex;
		justify-content: flex-end;
		flex: 0 0 340px;

		&__item {
			> a {
				padding: 5px 20px;
				transition: opacity .2s linear;

				&:hover {
					opacity: .75;
				}
			}
		}

		&__downloadlink {
			background-color: var(--color-txt);
			border: 1px solid var(--color-txt);
			border-radius: 0 0 0 4px;
		}

		&__contactlink {
			background-color: var(--color-prim);
			border: 1px solid var(--color-prim);
			border-radius: 0 0 4px;
		}
	}
}

// コンパクトヘッダー
.l-mainheader.is-compact {
	@include m.mq-pc {
		#{$_root}__inner {
			width: 100%;
			height: 65px;
			margin: 0;
			border-radius: 0;
		}

		#{$_root}__mainlogo {
			opacity: 0;
			visibility: hidden;
			height: 0;
		}

		#{$_root}__compactlogo {
			display: block;
		}

		#{$_root}__nav {
			padding-top: 5px;
			padding-right: 130px;
		}

		.l-mainheader-sublink {
			height: 0;
			visibility: hidden;
			opacity: 0;
			pointer-events: none;
		}

		.l-mainheader-utilities {
			&__item {
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);

				> a {
					width: 36px;
					height: 36px;
					padding: 5px 0;
					border-radius: 50%;

					> i {
						margin-right: 0;
					}

					> span {
						display: inline-block;
						text-indent: -9999px;
					}
				}

				&:first-child {
					right: 70px;
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1190px) {
		#{$_root}__logo {
			img {
				width: auto;
				height: 50px;
			}
		}
		#{$_root}__nav {
			padding-right: 100px;
		}
	}
}


// SPハンバーガーメニューオープン
.l-mainheader.is-open {
	.l-mainheader__inner {
		border-radius: 5px 5px 0 0;
	}

	.l-mainheader__navtoggle {
		&.is-open {
			span {
				&:nth-child(1) {
					transform: translateY(8px) rotate(-325deg);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					transform: translateY(-8px) rotate(325deg);
				}
			}
		}
	}

	@include m.mq-sp {
		&::before {
			display: none;
		}

		.l-mainheader__nav {
			&.is-open {
				//height: calc(100vh - 62px);
				height: calc(var(--vh) - 62px); /* stylelint-disable-line custom-property-pattern */
				opacity: 1;
				visibility: visible;
				z-index: 100;
			}
		}

		.l-mainheader__nav-body {
			margin-bottom: 10px;
			padding: 22px 12px 40px;
			background-color: #fff;
			border-radius: 0 0 5px 5px;
		}


	}
}
