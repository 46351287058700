@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main navigation
----------*/
$_root: '.l-mainnav';

.l-mainnav {
	/* stylelint-disable no-descending-specificity */
	@include m.mq-sp {
		//margin-top: 22px;

		&__list {
			border-bottom: 1px solid var(--color-border);

			&:first-child {
				border-top: 1px solid var(--color-border);
			}
		}

		&__sub {
			position: relative;
			display: none;
		}

		&__cattop {
			position: relative;

			> button {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 2;
				width: 60px;
				height: 100%;
				border-left: 1px solid var(--color-border);
				cursor: pointer;
			}

			> a {
				display: block;
				color: var(--color-txt);
			}

			> a > span,
			> span {
				position: relative;
				display: block;
				padding: 15px 60px 16px 10px;
				font-size: 1.6rem;
				font-weight: 500;

				&::before {
					@include m.iconfont-default;
					display: inline-block;
					content: '\EA01';
					color: var(--color-prim);
					font-size: 1.6rem;
					line-height: 1.6;
					margin-right: 10px;
					vertical-align: middle;
				}
			}
		}
	}

	@include m.mq-pc {
		&__main {
			display: flex;
			justify-content: flex-end;
		}

		&__cattop {
			> button {
				display: none;
			}

			> a {
				display: block;
				transition: color .3s;
				color: var(--color-txt);

				&::before {
					content: '';
					display: block;
					width: calc(100% - 25px);
					height: 3px;
					background-color: var(--color-prim);
					position: absolute;
					bottom: 0;
					left: 25px;
					z-index: 1;
					transition: all .3s;
					transform: scale(0,1);
					transform-origin: left;
					z-index: 9999;
				}

				&:hover {
					color: var(--color-prim);
					text-decoration: none;

					&::before {
						transform: scale(1,1);
					}
				}
			}

			> span,
			> a > span {
				display: block;
				padding: 17px 0 22px 25px;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: var(--line-height-s);

				&:hover {
					color: var(--color-prim);
					text-decoration: none;
				}
			}

			&.is-hover {
				> a {
					color: var(--color-prim);

					&::before {
						transition: none;
						transform: none;
					}
				}
			}
		}

		&__sub {
			opacity: 0;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			pointer-events: none;
			width: 100%;
			transition: opacity .2s ease-in-out;

			&.is-hung {
				opacity: 1;
				pointer-events: auto;
				z-index: 100;
				transition-delay: .5s;
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1150px) {
		&__cattop {
			> a {
				&:hover {
					&::before {
						width: calc(100% - 20px);
						left: 20px;
					}
				}
			}

			> span,
			> a > span {
				padding-left: 20px;
				// padding-bottom: 13px;
			}
		}
	}
}

$_megamenu: '.l-megamenu';

.l-megamenu {
	@include m.mq-sp {
		padding-left: 0;
		padding-right: 0;

		&--company,
		&--sustainability {
			#{$_megamenu}__category {
				flex: 1;
			}
		}

		&__categories {
			display: flex;
			flex-wrap: wrap;

			.l-megamenu__item {
				padding: 5px 0;
			}
		}

		&__category {
			flex: 0 0 50%;
			padding-bottom: 30px;
		}

		&__cattitle {
			margin-bottom: 10px;

			> a {
				font-size: 1.4rem;
				font-weight: 500;
				color: var(--color-txt);
				line-height: var(--line-height-s);
			}

			i {
				display: none;
			}
		}

		&__text {
			> a {
				font-size: 1.3rem;
				line-height: var(--line-height-s);
				color: var(--color-txt-caption);
			}

			i {
				display: none;
			}
		}
	}

	@include m.mq-pc {
		padding-top: 60px;
		padding-bottom: 74px;
		border-radius: 0 0 5px 5px;
		overflow: hidden;

		&__inner {
			@include m.inner-content;
			display: flex;
			flex-wrap: wrap;
		}

		&__categories {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		&__category {
			flex: 1 1 calc( 25% - 15px);

			+ .l-megamenu__category {
				margin-left: 15px;
			}
		}

		&__cattop {
			+ .l-megamenu__items {
				padding-left: 30px;
			}
		}

		&__cattitle {
			margin-bottom: 18px;

			> a {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 1.8rem;
				color: var(--color-txt);
				line-height: var(--line-height-s);
				transition: color .2s;

				&:hover {
					color: var(--color-prim);
				}
			}

			i {
				color: var(--color-prim);
				font-size: 1.8rem;
				padding-right: 12px;
				transform: translateY(2px);
			}

			& + & {
				margin-top: 8px;
			}
		}

		&__text {
			margin-bottom: 8px;

			> a {
				font-size: 1.6rem;
				color: var(--color-txt);
				line-height: var(--line-height-l);
				transition: color .2s;

				&:hover {
					color: var(--color-prim);
					text-decoration: underline;
				}
			}
		}
	}

	@include m.mq-pc {
		&__categories {
			&--featured {
				flex: 0 0 260px;
				margin-right: 40px;

				.l-megamenu__text {
					margin-bottom: 10px;

					&__inner {
						> span {
							font-size: 2.1rem;
						}
					}
				}
			}

			&--regular {
				.l-megamenu__item {
					width: calc(50% - 20px);
				}

				.l-megamenu__text {
					font-size: 1.5rem;
				}
			}
		}
	}
}

// SPハンバーガーメニューオープン
.l-mainheader.is-open {
	.l-mainnav {
		&__sub {
			&.is-open {
				display: block;
				padding: 20px 20px 0;
				background-color: #fff;
				z-index: 1;
			}
		}

		&__cattop {
			&.is-hierarichical {
				> button {
					display: block;
					color: var(--color-txt);

					&::before {
						@include m.iconfont-default;
						display: block;
						content: '\EA05';
						color: inherit;
						font-size: 1.1rem;
						transition: transform .2s ease;
					}

					&.is-open {
						&::before {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
}

// プルダウン背景
.l-mainnav__panel {
	position: absolute;
	width: calc(100% - 30px);
	border-radius: 0 0 5px 5px;
	height: 0;
	top: 108px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	transition: .5s cubic-bezier(.55, .05, .36, 1);
}

.l-mainheader.is-compact {
	.l-mainnav__panel {
		width: 100%;
		top: 65px;
	}
}

// メガメニュー開いた時の黒背景
.l-bg-gnav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0;
	z-index: -2;
	transition: .35s;

	&.is-show {
		opacity: .4;
		z-index: 90;
	}
}
