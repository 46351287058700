@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main content
----------*/

// コンテンツ全体枠
.l-content {
	width: 100%;
	position: relative;
	overflow-x: hidden;
	padding-top: 64px;
	padding-bottom: 100px;
	background: url(/assets/images/bg_body.png) no-repeat;
	background-position: top;
	background-size: 100% auto;

	@include m.mq-pc {
		padding-top: 111px;
		padding-bottom: 150px;

		// PC最小幅を固定する場合は下記を設定
		// min-width: 1240px;
	}

	&--v2 {
		padding-bottom: 80px;

		@include m.mq-pc {
			padding-bottom: 120px;
		}
	}

	@media print {
		padding-top: 0;
	}
}

// h1ヘッダーコンテンツ
.l-container-header {
	padding-bottom: 30px;
	border-bottom: 1px solid var(--color-border);

	&__inner {
		@include m.inner-content;
		position: relative;
	}

	&__labels {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: 0;
		margin-top: 22px;

		> li {
			margin-top: 8px;
			margin-right: 15px;
		}
	}

	&__date {
		font-family: var(--font-inter);
		font-weight: bold;
		font-size: 1.5rem;
		color: var(--color-txt);
	}

	&__sublabel {
		height: 38px;
		padding: 0 24px;
		border-left: 5px solid var(--color-prim);
		border-bottom-right-radius: 10px;
		position: absolute;
		bottom: -68px;
		left: percentage( math.div(20, 375) );
		background-color: var(--color-black);
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 38px;
		color: #fff;
	}

	@include m.mq-pc {
		padding-bottom: 50px;

		&__sublabel {
			height: 46px;
			bottom: -96px;
			left: 20px;
			font-size: 1.8rem;
			line-height: 46px;
		}
	}
}

// メインコンテンツ
.l-structure {
	&--double {
		@include m.inner-content;

		.l-structure__side {
			margin-top: 40px;
		}
	}

	@include m.mq-pc {
		&--double {
			display: flex;

			.l-structure__main {
				flex: 1;
			}

			.l-structure__side {
				width: percentage( math.div(278, 1200) );
				margin-left: percentage( math.div(30, 1200) );
				margin-top: 0;
				padding: 0;
			}
		}
	}
}

// セクション枠 最大幅設定・中央配置
.l-section {
	@include m.inner-content;

	&--gapless {
		padding-left: 0;
		padding-right: 0;
	}
}

// パンくずリスト
.l-breadcrumb {
	position: relative;
	padding: 20px 0 12px;
	overflow-x: auto;
	z-index: 2;

	&__list {
		@include m.inner-content;
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		font-size: 1.1rem;
		line-height: 1.2;

		> li {
			display: block;

			> a {
				text-decoration: underline;
				color: var(--color-txt-caption);
			}

			+ li {
				&::before {
					content: '>';
					display: inline-block;
					margin: 0 8px;
				}
			}

			&:last-child {
				> a {
					text-decoration: none;
					pointer-events: none;
					color: var(--color-txt);
				}
			}
		}
	}

	&--v2 {
		.l-breadcrumb__list {

			> li {
				> a {
					color: #fff
				}

				+ li {
					&::before {
						color: #fff;
					}
				}

				&:last-child {
					> a {
						color: #fff;
					}
				}
			}
		}
	}

	@include m.mq-pc {
		padding-bottom: 24px;

		&__list {
			display: block;
			white-space: normal;
			font-size: 1.4rem;
			line-height: 2.2;

			> li {
				display: inline;

				> a {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	@media print {
		display: none;
	}
}

.l-localnav {
	background: #fff;
	padding-top: 50px;
	padding-bottom: 90px;
	margin-bottom: -30px;

	&__title {
		font-weight: 500;
		font-size: 1.6rem;
		color: var(--color-txt);
		line-height: var(--line-height-s);
		margin-bottom: 30px;
	}

	&__inner {
		@include m.inner-content;
		padding-top: 30px;
		padding-bottom: 40px;
	}

	@include m.mq-pc {
		padding-top: 80px;
		padding-bottom: 190px;
		margin-bottom: -40px;

		&__inner {
			padding: 0 20px;
		}

		&__title {
			font-size: 2rem;
			margin-bottom: 50px;
		}
	}

	@media print {
		display: none;
	}
}
