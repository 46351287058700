@use '_mixin' as m;
// ----------
// Utility
// ----------

/*----------
	margin
----------*/

/* 汎用マージン */
.u-margin-xs {
	margin-top: 20px!important;
	@include m.mq-pc {
		margin-top: 30px!important;
	}
}

.u-margin-sm {
	margin-top: 40px!important;
	@include m.mq-pc {
		margin-top: 50px!important;
	}
}

.u-margin-md {
	margin-top: 60px!important;
	@include m.mq-pc {
		margin-top: 80px!important;
	}
}

.u-margin-lg {
	margin-top: 80px!important;
	@include m.mq-pc {
		margin-top: 120px!important;
	}
}

.u-margin-xl {
	margin-top: 100px!important;
	@include m.mq-pc {
		margin-top: 150px!important;
	}
}

.u-margin-none {
	margin-top: 0!important;
}

.u-margin-10 {
	margin-top: 10px !important;
}

/*----------
	display
----------*/
.u-pc-v {
	@include m.mq-sp {
		display: none;
	}
}

.u-sp-v {
	@include m.mq-pc {
		display: none;
	}
}


/*----------
	text
----------*/
.u-pcbr {
	@include m.mq-sp {
		display: none;
	}
}

.u-spbr {
	@include m.mq-pc {
		display: none;
	}
}

// .u-txt-fz10 {
// 	font-size: 1rem !important;
// }

// .u-txt-fz12 {
// 	font-size: 1.2rem !important;
// }

// .u-txt-fz13 {
// 	font-size: 1.3rem !important;
// }

// .u-txt-fz14 {
// 	font-size: 1.4rem !important;
// }

// .u-txt-fz16 {
// 	font-size: 1.6rem !important;
// }

// .u-txt-fz18 {
// 	font-size: 1.8rem !important;
// }

// .u-txt-fz20 {
// 	font-size: 2rem !important;
// }

.u-txt-center {
	text-align: center !important;
}

.u-txt-pccenter {
	@include m.mq-pc {
		text-align: center !important;
	}
}

.u-txt-right {
	text-align: right !important;
}

.u-txt-left {
	text-align: left !important;
}

.u-txt-bold {
	font-weight: bold !important;
}

.u-txt-strike {
	text-decoration: line-through !important;
}

.u-text-indent {
	text-indent: -3em;
	padding-left: 3em;
}

.u-txt-marker {
	font-weight: bold;
	background-image: linear-gradient(transparent 80%, #FFF7A6 80%);
}

.u-txt-prim {
	color: var(--color-prim) !important;
}

.u-txt-seco {
	color: var(--color-seco) !important;
}

.u-txt-default {
	color: var(--color-txt) !important;
}

.u-txt-white {
	color: #fff !important;
}

.u-txt-caution {
	color: var(--color-caution) !important;
}

/* 文中リンク */
.u-txt-link {
	text-decoration: underline;
	color: var(--color-txt-link);
	@include m.mq-pc {
		transition: all .2s;

		&:hover {
			text-decoration: none;
		}
	}
}
